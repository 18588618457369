.container {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  box-shadow: 10px 10px 10px lightgrey;
  padding: 1rem;
  margin: 10px;
  min-height: 60vh;
}
.header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.buttonTab {
  cursor: "pointer";
  width: 148px;
  height: 43px;
  padding: 12px 19p, 12px 19px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0px 0px 1px 0px;
  gap: 4px;
  color: #67748e;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0.34px;
  transition: 0.2s all ease-in-out;
  text-align: center;
  border-bottom: 1px solid #ffffff;
}
.active {
  border-bottom: 1px solid #67748e;
  font-family: Montserrat;
  font-size: 12px;
  transition: 0.2s all ease-in-out;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.34px;
  text-align: center;
}
.emptycardContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
