@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
	margin: 0;
	padding: 0;
	font-family: "Montserrat", sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
	/* Hide scrollbar for Chrome, Safari and Opera */
	.no-scrollbar::-webkit-scrollbar:horizontal {
		display: none;
	}
	/* Hide scrollbar for IE, Edge and Firefox */
	.no-scrollbar:horizontal {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}

	.no-vertical-scrollbar::-webkit-scrollbar:vertical {
		display: none;
	}
	.no-vertical-scrollbar:horizontal {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
}
:root {
	--navbar-height: 0px;
	--toggled-sidebar-width: 264px; /* desktop */
	--sidebar-width: 80px;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.sidebar-w {
	width: var(--sidebar-width);
}
.toggled-sidebar-w {
	width: var(--toggled-sidebar-width);
}
.m-from-sidebar-w {
	margin-left: var(--sidebar-width);
}
.m-from-toggled-sidebar-w {
	margin-left: var(--toggled-sidebar-width);
}
.outlet {
	height: calc(100vh - var(--navbar-height));
	overflow-y: auto;
	padding: 8px;
	/* max-width: 100vw; */
	overflow-x: hidden;
}
.student-grid-header {
	padding-left: 12px !important;
	font-size: 12px;
	font-weight: 700;
	color: rgba(131, 146, 171, 0.7);
	font-family: "roboto";
	letter-spacing: 0.1876px;
	text-transform: uppercase;
}

.MuiDataGrid-root {
	border: none !important;
}

.MuiDataGrid-columnHeadersInner div div:nth-child(3) div div {
	display: flex !important;
	justify-content: center !important;
}

.MuiDataGrid-row div:nth-child(3) {
	justify-content: center !important;
}

.MuiDataGrid-columnHeadersInner div div:nth-child(2) div div {
	display: flex !important;
	justify-content: center !important;
}

.MuiDataGrid-row div:nth-child(2) {
	justify-content: center !important;
}

.left-aligned-grid
	.MuiDataGrid-columnHeadersInner
	div
	div:nth-child(3)
	div
	div {
	display: flex !important;
	justify-content: start !important;
}

.left-aligned-grid .MuiDataGrid-row div:nth-child(3) {
	justify-content: flex-start !important;
}

.left-aligned-grid
	.MuiDataGrid-columnHeadersInner
	div
	div:nth-child(2)
	div
	div {
	display: flex !important;
	justify-content: start !important;
}

.left-aligned-grid .MuiDataGrid-row div:nth-child(2) {
	justify-content: start !important;
}

.MuiDataGrid-columnHeadersInner div div:nth-child(4) div div {
	display: flex !important;
	justify-content: center !important;
}

.MuiDataGrid-row div:nth-child(4) {
	justify-content: center !important;
}

.MuiDialog-paper {
	padding: 32px !important;
}

.addStudent-form {
	margin-top: 32px;
	margin-bottom: 80px;
	display: flex;
	flex-direction: column;
	gap: 16px;
}
.studentDialog-input {
	display: flex;
	background: #ffffff;
	padding-inline: 14px;
	border: 1px solid #e9f0f2;
	border-radius: 4px;
	min-width: 320px;
}
textarea.studentDialog-input {
	padding: 14px;
	outline: none;
	resize: none;
}
textarea::placeholder.studentDialog-input {
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: -0.04em;
	color: #687a7d;
}
.studentDialog-label {
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: -0.04em;
	color: #536163;
	margin-bottom: 4px;
}
.studentDialog-input .MuiInputBase-root {
	border: 0 !important;
	box-shadow: 0 !important;
}
.studentDialog-input .css-1mp910a-MuiInputBase-root.Mui-focused {
	box-shadow: 0 0 !important;
}

.studentDialog-label--light {
	color: rgba(200, 200, 200, 1);
	font-weight: 600;
}

.confirmatonForm-buttons--container {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.select-input {
	width: 100%;
	border: 0;
	padding: 0.5rem 0.75rem;
	outline: none;
}
.select-input {
	line-height: 20px;
	color: #687a7d;
}

.MuiDataGrid-row {
	cursor: pointer;
}

.add-student-btn,
.confirm-btn {
	position: relative;
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
	outline: 0px;
	border: 0px;
	margin: 0px;
	cursor: pointer;
	vertical-align: middle;
	appearance: none;
	text-decoration: none;
	font-family: Montserrat, Helvetica, Arial, sans-serif;
	min-width: 64px;
	display: inline-flex;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	font-size: 0.75rem;
	font-weight: 700;
	border-radius: 0.5rem;
	line-height: 1.4;
	text-align: center;
	text-transform: uppercase;
	user-select: none;
	transition: all 150ms ease-in 0s;
	min-height: 2.5rem;
	box-shadow: rgb(0 0 0 / 11%) 0rem 0.25rem 0.4375rem -0.0625rem,
		rgb(0 0 0 / 7%) 0rem 0.125rem 0.25rem -0.0625rem;
	padding: 0.75rem 1.5rem;
	background-image: initial;
	background-position-y: initial;
	background-repeat: initial;
	background-attachment: initial;
	background-origin: initial;
	background-clip: initial;
	background-color: rgb(44, 204, 211);
	color: rgb(255, 255, 255);
	background-size: 150% !important;
	background-position-x: 25% !important;
}

.cancel-btn {
	position: relative;
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
	outline: 0px;
	margin: 0px;
	cursor: pointer;
	vertical-align: middle;
	appearance: none;
	text-decoration: none;
	font-family: Montserrat, Helvetica, Arial, sans-serif;
	min-width: 64px;
	border: 1px solid rgb(44, 204, 211);
	display: inline-flex;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	font-size: 0.75rem;
	font-weight: 700;
	border-radius: 0.5rem;
	line-height: 1.4;
	text-align: center;
	text-transform: uppercase;
	user-select: none;
	transition: all 150ms ease-in 0s;
	min-height: 2.625rem;
	padding: 0.75rem 1.5rem;
	background-image: initial;
	background-position-y: initial;
	background-repeat: initial;
	background-attachment: initial;
	background-origin: initial;
	background-clip: initial;
	background-color: transparent;
	color: rgb(44, 204, 211);
	background-size: 150% !important;
	background-position-x: 25% !important;
}

.muidatagrid-columnheaders,
& .muidatagrid-columnheader {
	display: "none";
}
.muidatagrid-virtualscroller {
	margintop: "0 !important";
}
.resize-none {
	resize: none;
}
.MuiBox-root {
	/* background-color: red; */
	overflow-y: hidden;

	overflow-x: hidden;
	max-height: 95vh;
}
.MuiDataGrid-virtualScroller {
	-webkit-overflow-scrolling: tch;
}

/* TODO: commented for showing scrollbar as they want, if another time they want it reback just uncomment */
/* .MuiDataGrid-virtualScroller::-webkit-scrollbar {
  display: none;
} */

.overflow-control-limit-two-rows {
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	max-height: 4rem;
	-webkit-box-orient: vertical;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	overflow: hidden;
	text-decoration: none;
}

input[type="checkbox"]:not(.PrivateSwitchBase-input) {
	accent-color: #2cccd3;
	width: 20px;
	height: 20px;
	position: relative;
	cursor: pointer;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	border: 2px solid #2cccd3;
	border-radius: 3px;
	outline: none;
}

input[type="checkbox"]:checked {
	background-color: #2cccd3;
}

input[type="checkbox"]:checked::before {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	width: 6px;
	height: 10px;
	border: solid white;
	border-width: 0 2px 2px 0;
	transform: translate(-50%, -50%) rotate(45deg);
}

.google-autocomplete input {
	margin-block: 6px !important;
}

.text-overflow {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

/* input[type="radio"]:after {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: white;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #2cccd3;
} */

/* input[type="radio"]:checked:after {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #2cccd3;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
} */

/* .rounded_custom-radio-group .radio-label {
  display: flex;
  align-items: center;
  justify-items: center;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #2c3030;
  gap: 8px;
}

.rounded_custom-radio {
  appearance: none;
  width: 24px;
  height: 24px;
  border: 2px solid #2cccd3;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
}

.rounded_custom-radio:checked {
  background-color: #2cccd3;
  border-color: #2cccd3;
}

.rounded_custom-radio:checked:after {
  content: "";
  width: 6px;
  height: 6px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
} */

/* .radio-label:hover .rounded_custom-radio {
  border-color: #1aa6a7;
} */

input[type="radio"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	width: 24px;
	height: 24px;
	border: 2px solid #2cccd2;
	border-radius: 50%;
	display: inline-block;
	position: relative;
	cursor: pointer;
	outline: none;
	background-color: white;
}

input[type="radio"]:checked {
	background-color: #2cccd3;
}

input[type="radio"]:checked::after {
	content: "";
	width: 20px;
	height: 20px;
	background-color: #2cccd3;
	border: 2px solid white;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield; /* For Firefox */
}

.MuiFormControl-root.MuiTextField-root {
	margin-top: 0;
}

.chart-wrapper {
	width: 500px;
}

/* this css only for mediatable */
.custom-tCell {
	width: 600px;
	background-color: red;
	margin: 1px;
	border: 2px dotted pink;
}
@media screen and (max-width: 768px) {
	:root {
		--navbar-height: 80px;
	}

	.chart-wrapper {
		width: 95vw;
	}

	.m-from-sidebar-w {
		margin-left: 0;
	}
	.m-from-toggled-sidebar-w {
		margin-left: 0;
	}
}

.button_primary {
	@apply w-fit rounded-lg text-nowrap bg-[#2cccd3] hover:bg-opacity-90 active:bg-opacity-70 text-white font-montserrat text-base px-4 py-2 flex items-center gap-4;
}

button:disabled {
	@apply cursor-not-allowed bg-gray-300;
}
