.container {
	width: 375px;
	height: 194px;
	border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    justify-content: center;
}
