@import url("https://cdn.syncfusion.com/ej2/material.css");

.sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}
.nav-item,
.navbar {
  z-index: 10000;
}

.border {
  border: 1px solid var(--strock-color-web, #e9f0f2);
}
.border-left {
  border-left: 1px solid var(--strock-color-web, #e9f0f2);
}
.border-right {
  border-right: 1px solid var(--strock-color-web, #e9f0f2);
}

.sidebar-line {
  margin: 16px 0;
  /* width: 250px; */
  width: 100%;
  height: 1px;
  flex-shrink: 0;
  background: linear-gradient(
    90deg,
    rgba(52, 71, 103, 0) 0%,
    rgba(52, 71, 103, 0.13) 50%,
    rgba(52, 71, 103, 0) 100%
  );
}

.shadow {
  box-shadow: 0px 2px 4px -1px rgba(20, 20, 20, 0.07),
    0px 4px 6px -1px rgba(20, 20, 20, 0.12);
}
@media screen and (max-width: 1024px) {
  .sidebar {
    z-index: 10;
  }
}

.e-dlg-center-center,
.e-quick-popup-wrapper.e-device {
  z-index: 1000000 !important;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

.no-scroll {
  ::-webkit-scrollbar {
    display: none;
  }
}

/* color-picker style  */

#preview {
  background: transparent
    url("https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png")
    no-repeat;
  display: inline-block;
  height: 80px;
  margin: 10px 0;
  min-width: 300px;
  max-width: 600px;
  background-color: #008000;
}

.date-picker {
  display: none !important;
}

.e-input-group:not(.e-float-icon-left),
.e-input-group.e-success:not(.e-float-icon-left),
.e-input-group.e-warning:not(.e-float-icon-left),
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
  border: none;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  background-color: #f2f8fa !important;
  border-radius: 16px !important;
  padding: 15px 20px !important;
  max-width: fit-content !important;
  max-height: fit-content !important;
}

.title_threads_top {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.11999999731779099px;
  text-align: left;
  color: #344767;
  margin: 10px 10px;
}

.show-date {
  display: inline-flex !important;
}

.hide-date {
  display: none !important;
}

.css-uhb5lp {
  max-width: fit-content !important;
  max-height: fit-content !important;
}

.MuiPaper-root
  .MuiPaper-elevation
  .MuiPaper-rounded
  .MuiPaper-elevation24
  .MuiDialog-paper
  .MuiDialog-paperScrollPaper
  .MuiDialog-paperWidthSm
  .css-uhb5lp {
  max-width: fit-content !important;
  max-height: fit-content !important;
}

.h-600 {
  height: 600px;
}

.w-300 {
  width: 300px;
}

.search-input {
  border: 1px solid var(--strock-color-web, #e9f0f2);
  height: 200px;
  overflow: scroll;
}

.search-input div:hover {
  background-color: #e9f0f2;
}

.scrollbar {
  scrollbar-width: thin;
}

.cursor {
  cursor: pointer;
}

.MuiDataGrid-selectedRowCount {
  visibility: hidden !important;
}

.overflow-control-limit-two-rows {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 4rem;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-decoration: none;
}
/* ::-webkit-scrollbar {
  display: none;
} */
