.topPart {
  display: flex;
  gap: 30px;
  align-items: flex-start;
}

.container {
  padding: 20px;
}

.topPart .left {
  width: 177px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.topPart .left img {
  max-width: 177px !important;
  max-height: 300px !important;
  border-radius: 15px !important;
}

.viewFullImage {
  border: 1px solid #67748e;
  color: #67748e;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.34px;
  text-align: center;
  width: 155px;
}

.right {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
}

.right .right_title {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.04em;
  text-align: left;
}

.right .right_des {
  font-family: Montserrat;
  font-size: 14px;
  margin-top: -10px;
  font-weight: 400;
  letter-spacing: -0.04em;
  text-align: left;
  color: #687a7d;
}

.right .content_card {
  width: 452px;
  padding: 8px 11px 8px 11px;
  border-radius: 10px;
  border: 1px;
  gap: 8px;
  border: 1px solid #e1e1e1;
  background-color: white;
}

.right .content_card .title {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #2c3030;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 3px;
}

.right .content_card .des {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.right .content_card .cont1 {
  display: flex;
  flex-direction: column;
  gap: -10px;
  align-items: flex-start;
}

.right .content_card .cont1 .title {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.04em;
  text-align: left;
  color: #687a7d;
  border: None;
}

.right .content_card .cont1 .des {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  margin-top: -4px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #2c3030;
}

.bottomPart {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
  justify-content: flex-end;
  margin-top: 20px;
}

.startThreadButton {
  max-height: 40px;
  width: 205px;
  justify-content: center;
  border-radius: 8px;
  border: 1px;
  gap: 10px;
  color: #67748e;
  display: flex;
  align-items: center;
  padding: 12px 25px;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid #67748e;
  transition: 0.2s all ease-in-out;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: -0.04em;
  text-align: left;
}

.startThreadButton:hover {
  color: white;
  background-color: #67748e;
}

.rejectButton {
  border-radius: 8px;
  width: 205px;
  justify-content: center;
  max-height: 40px;
  border: 1px;
  gap: 10px;
  color: white;
  display: flex;
  background-color: #de2b4c;
  align-items: center;
  padding: 12px 25px;
  border-radius: 8px;
  transition: 0.2s all ease-in-out;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: -0.04em;
  text-align: left;
}

.acceptButton {
  border-radius: 8px;
  width: 205px;
  justify-content: center;
  border: 1px;
  gap: 10px;
  max-height: 40px;
  color: white;
  display: flex;
  background-color: #2cccd3;
  align-items: center;
  padding: 12px 25px;
  border-radius: 8px;
  transition: 0.2s all ease-in-out;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: -0.04em;
  text-align: left;
}

.rejectButton:hover {
  background-color: #a00320;
}
.acceptButton:hover {
  background-color: #0d959c;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 8px;
  }
  .topPart {
    flex-direction: column;
  }
  .topPart .left {
    /* margin: auto; */
    justify-content: center;
    align-items: center;
    width: auto;

    width: 100%;
  }

  .right .content_card {
    width: 75vw;
    padding: 8px 11px 8px 11px;
    border-radius: 10px;
    border: 1px;
    gap: 8px;
    border: 1px solid #e1e1e1;
    background-color: white;
  }
}
