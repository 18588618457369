.container {
  /* height: 80vh; */
  height: 80%;
  border-radius: 16px;
  color: #ffffff;
  box-shadow: 0px 20px 27px 0px #0000000d;
  background: #ffffff;
  /* margin: 10px; */
}

.topFilterSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  gap: 20px;
}

.campaginName_image {
  width: 36px;
  height: 36px;
  border-radius: 8px;
}

.campaginName_image img {
  width: 36px;
  height: 36px;
  border-radius: 8px;
}

.campaginName_title {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.3999600112438202px;
  text-align: left;
  color: #344767;
}

.cellText {
  color: #8392ab;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  /* 125% */
  letter-spacing: 0.4px;
}

.campaginName_des {
  color: #8392ab;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  /* 125% */
  letter-spacing: 0.4px;
}

.buttonTab {
  width: 148px;
  height: 43px;
  padding: 12px 19p, 12px 19px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0px 0px 1px 0px;
  gap: 4px;
  color: #67748e;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0.34px;
  transition: 0.2s all ease-in-out;
  text-align: center;
  border-bottom: 1px solid #ffffff;
}

.active {
  border-bottom: 1px solid #67748e;
  font-family: Montserrat;
  font-size: 12px;
  transition: 0.2s all ease-in-out;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.34px;
  text-align: center;
}

.badge {
  background-color: #a8b3c4;
  color: white;
  transition: 0.2s all ease-in-out;
  border-radius: 14px;
  width: 27px;
  height: 19px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.activeBadge {
  background-color: #2cccd3;
}

.actionCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.selectRows {
  padding: 8px 30px 8px 30px;
  border-radius: 8px;
  border: 1px;
  gap: 4px;
  border: 1px solid #67748e;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.34px;
  text-align: center;
  color: #67748e;
  display: flex;
  align-items: center;
  z-index: 11;
  background-color: "white";
  gap: 10px;
}

.rowsActive,
.rowsActive img {
  background-color: #67748e !important;
  color: white;
}
