.container {
	height: 80vh;
	border-radius: 16px;
	box-shadow: 0px 20px 27px 0px #0000000d;
	background: #ffffff;
	margin: 10px;
    padding: 10px;
}

.campaginName_image {
	width: 36px;
	height: 36px;
	border-radius: 8px;
}

.campaginName_image img {
	width: 36px;
	height: 36px;
	border-radius: 8px;
}

.campaginName_title {
	font-family: Roboto;
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 0.3999600112438202px;
	text-align: left;
	color: #344767;
}

.cellText {
	color: #8392ab;
	font-family: Roboto;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 15px; /* 125% */
	letter-spacing: 0.4px;
}

.campaginName_des {
	color: #8392ab;
	font-family: Roboto;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 15px; /* 125% */
	letter-spacing: 0.4px;
}
