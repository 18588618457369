.container {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  box-shadow: 10px 10px 10px lightgrey;
  padding: 10px 20px;
  margin: 10px;
}

.title {
  color: #536163;
  text-align: center;
  font-family: Montserrat;
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.343px;
}

.header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.form_title {
  overflow: hidden;
  color: #2c3030;
  text-overflow: ellipsis;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.64px;
}
.form_overflow_title {
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
}

.form__des {
  color: #687a7d;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: -0.56px;
}

.uploadIcon {
  display: flex;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: #e9e9e9;
}

.uploadIcontext {
  color: #2cccd3;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  width: 100px;
  margin-top: 10px;
  font-weight: 500;
  line-height: 150%; /* 21px */
  letter-spacing: -0.56px;
}

.successtext {
  color: #2c3030;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  margin-left: 10px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.64px;
  text-transform: capitalize;
}

.buttonTab {
  cursor: "pointer";
  width: 148px;
  height: 43px;
  padding: 12px 19p, 12px 19px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0px 0px 1px 0px;
  gap: 4px;
  color: #67748e;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0.34px;
  transition: 0.2s all ease-in-out;
  text-align: center;
  border-bottom: 1px solid #ffffff;
}

.active {
  border-bottom: 1px solid #67748e;
  font-family: Montserrat;
  font-size: 12px;
  transition: 0.2s all ease-in-out;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.34px;
  text-align: center;
}

.cardContainer {
  display: flex;
  width: 217px;
  padding: 10px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 8px;
  background: #f8f8f8;
}

.emptycardContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardFooter {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.34px;
  text-align: center;
  color: "#536163";
}

.cardDes {
  color: #536163;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.343px;
}

.cardDate {
  color: #536163;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.343px;
}

.cardStatus {
  display: flex;
  align-items: center;
  gap: 5px;
}

.cardCircle {
  width: 8px;
  height: 8px;
  border-radius: 100px;
  background: #7a7a7a;
}

.activeCircle {
  background-color: #22b600;
}

.formTextPlaceholder {
  overflow: hidden;
  color: #2c3030;
  text-overflow: ellipsis;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.56px;
}

.Formcircle {
  width: 24px;
  height: 24px;
  border-radius: 100px;
  border: 1px solid lightgrey;
}

.Formcircleactive {
  background-color: #2cccd3;
  border: 1px solid #2cccd3;
  box-shadow: 0 0 0px 2px #ffffff, 0 0 0px 3px #2cccd3;
}

.redeemButton {
  display: flex;
  width: 170px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 25px;
  color: #fff;
  background: #7e9194;
}

.imageContainer {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 300px;
  height: 100px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 10px;
  background: #dbeff5;
}

.imageContainer img {
  width: 300px;
  border-radius: 10px;
  height: 100px;
}

.imageContainer p {
  color: #687a7d;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.56px;
}
