.container {
  box-shadow: 0px 20px 27px 0px #0000000d;
  background-color: white;
  margin: 10px;
  width: 98%;
  height: 100%;
  border-radius: 16px;
  padding: 24px;
}

.title {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.34px;
  text-align: center;
  color: #536163;
}

.selectRows {
  padding: 8px 30px 8px 30px;
  border-radius: 8px;
  border: 1px;
  gap: 4px;
  border: 1px solid #67748e;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.34px;
  text-align: center;
  color: #67748e;
  display: flex;
  align-items: center;
  gap: 10px;
}

.rowsActive,
.rowsActive img {
  background-color: #67748e !important;
  color: white;
}
