.container {
	border-radius: 16px;
	box-shadow: 0px 20px 27px 0px #0000000d;
	background: #ffffff;
	display: flex;
	padding: 10px;
	flex-direction: row;
	height: 90vh;
	gap: 30px;
	justify-content: space-between;
}

.container .chats_list {
	display: flex;
	flex-direction: column;
	gap: 24px;
	align-items: flex-start;
	width: 365px;
	height: 100%;
	padding: 10px;
}

.chats_list .top {
	display: flex;
	padding-bottom: 24px;
	padding: 10px;
	border-bottom: 1px solid #e7e7e7;
	width: 100%;
}

.chats_list .top .title {
	display: flex;
	flex-direction: row;
	gap: 10px;
	align-items: center;
	justify-content: flex-start;
	font-family: Roboto;
	font-size: 20px;
	font-weight: 600;
	line-height: 26px;
	letter-spacing: 0.11999999731779099px;
	text-align: left;
	color: #344767;
}

.chats_list .top .badge {
	padding: 4px 12px;
	border-radius: 20px;
	gap: 8px;
	background: #2cccd3;
	font-family: Roboto;
	font-size: 16px;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
	letter-spacing: 0.11999999731779099px;
	text-align: left;
	color: #ffffff;
}

.chats_list .list {
	display: flex;
	flex-direction: column;
	gap: 24px;
	overflow-y: scroll;
	align-items: flex-start;
}

.chats_list .list .chat_view_card {
	width: 300px;
	height: 70px;
	border-radius: 12px;
	padding: 12px;
	gap: 16px;
	display: flex;
	justify-content: space-between;
	cursor: pointer;
	transition: 0.2s all ease-in-out;
}

.chat_view_card:hover {
	background-color: #1ecad312;
}

.active_card {
	background: #1ecad312;
}

.chat_view_card .user_info {
	display: flex;
	gap: 8px;
	align-items: flex-start;
}

.chat_view_card .user_info .details {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 2px;
}

.chat_view_card .user_info .details .title {
	font-family: Montserrat;
	font-size: 14px;
	font-weight: 600;
	line-height: 21px;
	letter-spacing: 0em;
	text-align: left;
	color: #344767;
}

.chat_view_card .user_info .details .des {
	font-family: Montserrat;
	font-size: 12px;
	font-weight: 600;
	line-height: 18px;
	letter-spacing: 0em;
	text-align: left;
	color: #00000066;
}

.chat_view_card .active_time {
	font-family: Montserrat;
	font-size: 14px;
	font-weight: 600;
	line-height: 21px;
	letter-spacing: 0em;
	text-align: left;
	color: #344767;
}

.chat_view_card .profile img {
	width:42px;
	height:42px;
	border-radius: 12px;
}

.message_seaction {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 20px;
	width: 90%;
	position: relative;
}

.message_seaction .top {
	border-bottom: 1px solid #e7e7e7;
	width: 100%;
}

.message_seaction .top .chat_name {
	display: flex;
	padding: 10px 30px;
	flex-direction: row;
	align-items: center;
	gap: 10px;
}

.message_seaction .top .chat_name .user_profile {
	width: 36px;
	height: 36px;
	border-radius: 9px;
}

.message_seaction .top .chat_name .user_profile img {
	width: 36px;
	height: 36px;
	border-radius: 9px;
}

.message_seaction .top .chat_name .user_name {
	font-family: Montserrat;
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	letter-spacing: 0em;
	text-align: left;
	color: #344767;
}

.messages {
	display: flex;
	flex-direction: column;
	padding: 10px 30px;
	overflow-y: auto;
	word-wrap: break-word;
	width: 90%;
	height: 79%;
}

.chat {
	gap: 16px;
	float: right !important;
	display: flex;
	align-items: flex-start;
	flex-direction: row-reverse;
}

.anotherchat {
	margin-top: 8px !important;
}

.chat_container {
	width: 100%;
}

.replay_message {
	float: left !important;
	flex-direction: row;
}

.chat .chat_content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
}

.replay_message .chat_content .message {
	max-width: 500px;
	padding: 8px 16px 8px 16px;
	border-radius: 12px;
	gap: 10px;
	background-color: #f1f1f1 !important;
	font-family: Montserrat;
	font-size: 14px;
	font-weight: 500;
	line-height: 21px;
	letter-spacing: 0em;
	text-align: left;
	color: #344767 !important;
}

.chat .chat_content .message {
	max-width: 500px;
	padding: 8px 16px 8px 16px;
	border-radius: 12px;
	gap: 10px;
	background-color: #2cccd3;
	font-family: Montserrat;
	font-size: 14px;
	font-weight: 500;
	line-height: 21px;
	letter-spacing: 0em;
	text-align: left;
	color: white;
}

.hidden {
	display: hidden;
}

.chat .chat_content .chat_time {
	font-family: Montserrat;
	font-size: 10px;
	font-weight: 500;
	line-height: 15px;
	letter-spacing: 0em;
	text-align: left;
	color: #344767;
}

.chat .profile img {
	width: 36px;
	height: 36px;
	border-radius: 8.33px;
}

.chat .profile {
	width: 36px;
	height: 36px;
}

.send_message_section {
	position: absolute;
	bottom: 0px;
	margin: 0 10px;
	padding: 20px;
	width: 90%;
}

.messageContainer_send {
	display: flex;
	flex-direction: row;
	gap: 10px;
	width: 100%;
	padding: 10px 20px;
	border: 1px solid #e2e8f0;
	align-items: center;
	background-color: white;
	border-radius: 12px;
}

.messageContainer_send .message_input {
	height: 100%;
	width: 100%;
	outline: none;
	font-family: Montserrat;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	letter-spacing: 0em;
	text-align: left;
	color: #344767;
}

.messageContainer_send .sendThread {
	border: none;
	background: transparent;
}

@media screen and (max-width: 700px) {
	.container .chats_list {
		display: none;
	}
}
