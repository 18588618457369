.blueButton {
	padding: 12px 10px;
	border-radius: 8px;
	gap: 10px;
	font-family: Roboto;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: -0.04em;
	text-align: center;
	background-color: #2cccd3;
	color: white;
	box-shadow: 0px 4px 7px -1px #0000001c;
	transition: .2s all ease-in-out;
}

.blueButtonOutlined {
	background-color: transparent !important;
	color: #2cccd3;
	border: 1px solid #2cccd3;
}

.blueButtonOutlined:hover {
	background-color: #2cccd3 !important;
	color: white;
}

.blueButton:hover {
	background-color: #08a0a8;
}

.redButton {
	padding: 12px 10px;
	border-radius: 8px;
	gap: 10px;
	font-family: Roboto;
	font-size: 16px;
	line-height: 19px;
	letter-spacing: -0.04em;
	text-align: center;
	background-color: #DE2B4C;
	color: white;
	box-shadow: 0px 4px 7px -1px #0000001c;
	transition: .2s all ease-in-out;
}

.redButtonOutlined {
	background-color: transparent !important;
	color: #DE2B4C;
	border: 1px solid #DE2B4C;
}

.redButtonOutlined:hover {
	background-color: #DE2B4C !important;
	color: white;
}

.redButton:hover {
	background-color: #94142c;
}