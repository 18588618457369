.container {
	display: flex;
	flex-direction: column;
	background-color: white;
	border-radius: 10px;
	box-shadow: 10px 10px 10px lightgrey;
	padding: 10px 20px;
	margin: 10px;
}

.title {
	color: #536163;
	text-align: center;
	font-family: Montserrat;
	text-align: left;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: 0.343px;
}

.header {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
}

.form_title {
	overflow: hidden;
	color: #2c3030;
	text-overflow: ellipsis;
	font-family: Montserrat;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -0.64px;
}

.form__des {
	color: #687a7d;
	font-family: Montserrat;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 21px */
	letter-spacing: -0.56px;
}

.uploadIcon {
	display: flex;
	width: 100px;
	overflow: hidden;
	height: 100px;
	justify-content: center;
	align-items: center;
	border-radius: 50px;
	background: #e9e9e9;
}

.uploadIcontext {
	color: #2cccd3;
	text-align: center;
	font-family: Montserrat;
	font-size: 14px;
	font-style: normal;
	width: 100px;
	margin-top: 10px;
	font-weight: 500;
	line-height: 150%; /* 21px */
	letter-spacing: -0.56px;
}

.successtext {
	color: #2c3030;
	font-family: Montserrat;
	font-size: 16px;
	font-style: normal;
	margin-left:10px;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -0.64px;
	text-transform: capitalize;
}
